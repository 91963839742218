.ar-button {
    width: 50%; 
    position: absolute; 
    top: 150px; 
    right: 0px;
    }
    .socialmedia {
    width: 100%; 
    bottom: 0px; 
    right: 0px;
    }
    .ar-button img {
    width: 100%; 
    }
    .ar-top {
    width: 100%; 
    margin: 0;
    }
    .ar-top img {
    width: 100%; 
    margin: 0;
    }
    .ar-top2 {
    width: 100%; 
    bottom: 0;
    margin-top: 10px; 
    }
    .ar-top2 img {
    width: 100%; 
    bottom: 0;
    }
    .ar-button img {
    width: 100%; 
    }
    body{   
    margin:0 auto; 
    background:#2e3a42;}
    
    button{
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: none;
    background-color: blue;
    box-sizing: border-box;
    }
    button[slot="hotspot-hand"]{
    --min-hotspot-opacity: 0;
    background-color: red;
    }
    button[slot="hotspot-foot"]:not([data-visible]) {
    background-color: transparent;
    border: 3px solid blue;
    }
    #annotation{
    background-color: #888888;
    position: absolute;
    transform: translate(10px, 10px);
    border-radius: 10px;
    padding: 10px;
    }
